import React, {useState} from "react";
import {
    Alert,
    Button,
    Checkbox,
    CircularProgress,
    MenuItem,
    Select,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import * as emailjs from "@emailjs/browser";
import {format} from "date-fns";
import {getI18n, useTranslation} from "react-i18next";
import {fr} from "date-fns/locale";
import {Link} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";

export default function FormOrganizeEventComponent() {
    const lang = getI18n().language;
    const language = lang === "fr" ? fr : null;
    const {t} = useTranslation();

    const {
        control,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            date: null,
            choice: "no-choice",
            number: "",
            name: "",
            email: "",
            phone: "",
            budget: "",
            comment: "",
            condition: false,
        },
    });

    const [loading, setLoading] = useState(false);
    const [ok, setOk] = useState(false);
    const [ko, setKo] = useState(false);

    // Date min : demain
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    // Styles d'erreur
    const errorFieldSx = {
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "red",
        },
    };
    const errorSelectSx = {
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "red",
        },
    };
    const checkboxErrorSx = (hasError) =>
        hasError
            ? {color: "red", "&.Mui-checked": {color: "red"}}
            : {};
    const helperTextProps = {
        style: {color: "red", fontWeight: "bold"},
        className: "se-f",
    };

    async function onSubmit(data) {
        setLoading(true);
        const formData = {
            date: data.date ? format(data.date, "dd/MM/yyyy") : null,
            choice: data.choice,
            number: data.number,
            name: data.name,
            email: data.email,
            phone: data.phone,
            budget: data.budget,
            comment: data.comment,
        };

        try {
            await emailjs.send(
                "service_rz3yueg",
                "template_70f2gwi",
                formData,
                "0d20fLIJpS0f1_C15"
            );
            setOk(true);
            reset();
        } catch (error) {
            setKo(true);
            console.error(error);
        }
        setLoading(false);
    }

    return (
        <form
            className="d-f-c-fs-c m-t-20 mi-ma-w-p-90"
            onSubmit={handleSubmit(onSubmit)}
        >
            <h3 className="t-a-l">{t("ORGANIZE_EVENT.FORM.DATE_TIME.TITLE")}</h3>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={language}>
                <Controller
                    name="date"
                    control={control}
                    rules={{required: t("ORGANIZE_EVENT.FORM.DATE_TIME.ERROR_DATE")}}
                    render={({field}) => (
                        <MobileDatePicker
                            {...field}
                            onChange={(value) => field.onChange(value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    color="secondary"
                                    placeholder={t("ORGANIZE_EVENT.FORM.DATE_TIME.PLACEHOLDER")}
                                    error={!!errors.date}
                                    helperText={errors.date ? errors.date.message : ""}
                                    FormHelperTextProps={helperTextProps}
                                    sx={errors.date ? errorFieldSx : {}}
                                    className="m-t-10 mi-w-p-100 se-f"
                                />
                            )}
                            minDate={tomorrow}
                            disableHighlightToday
                            showToolbar={false}
                            showDaysOutsideCurrentMonth
                        />
                    )}
                />
            </LocalizationProvider>

            <Controller
                name="choice"
                control={control}
                rules={{
                    required: t("ORGANIZE_EVENT.FORM.DATE_TIME.ERROR_CHOICE"),
                    validate: (value) =>
                        value !== "no-choice" || t("ORGANIZE_EVENT.FORM.DATE_TIME.ERROR_CHOICE"),
                }}
                render={({field}) => (
                    <>
                        <Select
                            {...field}
                            color="secondary"
                            className="m-t-10 mi-w-p-100 se-f"
                            error={!!errors.choice}
                            sx={errors.choice ? errorSelectSx : {}}
                        >
                            <MenuItem value="no-choice" className="se-f">
                                {t("ORGANIZE_EVENT.FORM.DATE_TIME.CHOICE_1")}
                            </MenuItem>
                            <MenuItem value="11h/15h" className="se-f">
                                {t("ORGANIZE_EVENT.FORM.DATE_TIME.CHOICE_2")}
                            </MenuItem>
                            <MenuItem value="15h/18h" className="se-f">
                                {t("ORGANIZE_EVENT.FORM.DATE_TIME.CHOICE_3")}
                            </MenuItem>
                            <MenuItem value="18h/00h" className="se-f">
                                {t("ORGANIZE_EVENT.FORM.DATE_TIME.CHOICE_4")}
                            </MenuItem>
                        </Select>
                        {errors.choice && (
                            <Typography
                                variant="caption"
                                sx={{color: "red", fontWeight: "bold", ml: 2}}
                                className="se-f m-t-5"
                            >
                                {errors.choice.message}
                            </Typography>
                        )}
                    </>
                )}
            />

            <h3 className="m-t-10 t-a-l">
                {t("ORGANIZE_EVENT.FORM.PEOPLE_NUMBER.TITLE")}
            </h3>
            <Controller
                name="number"
                control={control}
                rules={{
                    required: t("ORGANIZE_EVENT.FORM.PEOPLE_NUMBER.ERROR"),
                    min: {value: 1, message: t("ORGANIZE_EVENT.FORM.PEOPLE_NUMBER.ERROR")},
                }}
                render={({field}) => (
                    <TextField
                        {...field}
                        variant="outlined"
                        color="secondary"
                        className="mi-ma-w-p-100 se-f m-t-10"
                        type="number"
                        placeholder={t("ORGANIZE_EVENT.FORM.PEOPLE_NUMBER.PLACEHOLDER")}
                        error={!!errors.number}
                        helperText={errors.number ? errors.number.message : ""}
                        FormHelperTextProps={helperTextProps}
                        sx={errors.number ? errorFieldSx : {}}
                        autoComplete="off"
                    />
                )}
            />

            <h3 className="m-t-10 t-a-l">{t("ORGANIZE_EVENT.FORM.NAME.TITLE")}</h3>
            <Controller
                name="name"
                control={control}
                rules={{required: t("ORGANIZE_EVENT.FORM.NAME.ERROR")}}
                render={({field}) => (
                    <TextField
                        {...field}
                        variant="outlined"
                        color="secondary"
                        className="mi-ma-w-p-100 se-f m-t-10"
                        type="text"
                        placeholder={t("ORGANIZE_EVENT.FORM.NAME.PLACEHOLDER")}
                        error={!!errors.name}
                        helperText={errors.name ? errors.name.message : ""}
                        FormHelperTextProps={helperTextProps}
                        sx={errors.name ? errorFieldSx : {}}
                        autoComplete="off"
                    />
                )}
            />

            <h3 className="m-t-10 t-a-l">{t("ORGANIZE_EVENT.FORM.MAIL.TITLE")}</h3>
            <Controller
                name="email"
                control={control}
                rules={{required: t("ORGANIZE_EVENT.FORM.MAIL.ERROR")}}
                render={({field}) => (
                    <TextField
                        {...field}
                        variant="outlined"
                        color="secondary"
                        className="mi-ma-w-p-100 se-f m-t-10"
                        type="email"
                        placeholder={t("ORGANIZE_EVENT.FORM.MAIL.PLACEHOLDER")}
                        error={!!errors.email}
                        helperText={errors.email ? errors.email.message : ""}
                        FormHelperTextProps={helperTextProps}
                        sx={errors.email ? errorFieldSx : {}}
                        autoComplete="off"
                    />
                )}
            />

            <h3 className="m-t-10 t-a-l">{t("ORGANIZE_EVENT.FORM.PHONE.TITLE")}</h3>
            <Controller
                name="phone"
                control={control}
                rules={{required: t("ORGANIZE_EVENT.FORM.PHONE.ERROR")}}
                render={({field}) => (
                    <TextField
                        {...field}
                        variant="outlined"
                        color="secondary"
                        className="mi-ma-w-p-100 se-f m-t-10"
                        type="tel"
                        placeholder={t("ORGANIZE_EVENT.FORM.PHONE.PLACEHOLDER")}
                        error={!!errors.phone}
                        helperText={errors.phone ? errors.phone.message : ""}
                        FormHelperTextProps={helperTextProps}
                        sx={errors.phone ? errorFieldSx : {}}
                        autoComplete="off"
                    />
                )}
            />

            <h3 className="m-t-10 t-a-l">{t("ORGANIZE_EVENT.FORM.BUDGET.TITLE")}</h3>
            <Controller
                name="budget"
                control={control}
                rules={{required: t("ORGANIZE_EVENT.FORM.BUDGET.ERROR")}}
                render={({field}) => (
                    <TextField
                        {...field}
                        variant="outlined"
                        color="secondary"
                        className="mi-ma-w-p-100 se-f m-t-10"
                        type="number"
                        placeholder={t("ORGANIZE_EVENT.FORM.BUDGET.PLACEHOLDER")}
                        error={!!errors.budget}
                        helperText={errors.budget ? errors.budget.message : ""}
                        FormHelperTextProps={helperTextProps}
                        sx={errors.budget ? errorFieldSx : {}}
                        autoComplete="off"
                    />
                )}
            />

            <h3 className="m-t-10 t-a-l">{t("ORGANIZE_EVENT.FORM.COMMENT.TITLE")}</h3>
            <Controller
                name="comment"
                control={control}
                rules={{required: t("ORGANIZE_EVENT.FORM.COMMENT.ERROR")}}
                render={({field}) => (
                    <TextField
                        {...field}
                        variant="outlined"
                        color="secondary"
                        className="mi-ma-w-p-100 se-f m-t-10"
                        type="text"
                        placeholder={t("ORGANIZE_EVENT.FORM.COMMENT.PLACEHOLDER")}
                        error={!!errors.comment}
                        helperText={errors.comment ? errors.comment.message : ""}
                        FormHelperTextProps={helperTextProps}
                        sx={errors.comment ? errorFieldSx : {}}
                        autoComplete="off"
                        multiline
                        minRows={5}
                    />
                )}
            />

            <Controller
                name="condition"
                control={control}
                rules={{required: t("ORGANIZE_EVENT.FORM.CONDITION_ERROR")}}
                render={({field}) => (
                    <div className="flex item-center justify-center mi-w-p-100 m-t-10">
                        <div>
                            <div>
                                <Checkbox
                                    {...field}
                                    checked={field.value}
                                    color="secondary"
                                    onChange={(e) => field.onChange(e.target.checked)}
                                    sx={checkboxErrorSx(!!errors.condition)}
                                />
                                <Link to="/privacy-policy" className="f-p se-f t-u t-a-l">
                                    {t("ORGANIZE_EVENT.FORM.CONDITIONS")}
                                </Link>
                            </div>
                            {errors.condition && (
                                <Typography
                                    variant="caption"
                                    sx={{color: "red", fontWeight: "bold"}}
                                    className="se-f m-t-5"
                                >
                                    {errors.condition.message}
                                </Typography>
                            )}
                        </div>
                    </div>
                )}
            />

            <div className="d-f-c-c mi-w-p-100 m-t-20">
                <Button type="submit" color="secondary" className="pr-f f-s-16 f-w-b f-p">
                    {loading ? (
                        <CircularProgress color="secondary" size={24}/>
                    ) : (
                        t("ORGANIZE_EVENT.FORM.SEND")
                    )}
                </Button>
            </div>

            <Snackbar
                open={ok}
                autoHideDuration={5000}
                onClose={() => setOk(false)}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            >
                <Alert
                    onClose={() => setOk(false)}
                    severity="success"
                    className="se-f f-s-16 f-w-b d-f-r-sb t-a-l"
                >
                    {t("ORGANIZE_EVENT.FORM.IF_SEND.TEXT_1")}
                    <br/>
                    {t("ORGANIZE_EVENT.FORM.IF_SEND.TEXT_2")}
                </Alert>
            </Snackbar>
            <Snackbar
                open={ko}
                autoHideDuration={5000}
                onClose={() => setKo(false)}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            >
                <Alert
                    onClose={() => setKo(false)}
                    severity="error"
                    className="se-f f-s-16 f-w-b d-f-r-sb t-a-l"
                >
                    {t("ORGANIZE_EVENT.FORM.IF_ERROR.TEXT_1")}
                    <br/>
                    {t("ORGANIZE_EVENT.FORM.IF_ERROR.TEXT_2")}
                </Alert>
            </Snackbar>
        </form>
    );
}
