import React from "react";
import PrivacyPolicyContentComponent from "./privacy-policy-content.component";
import {useWindowWidth} from "../hooks/window.hook";

export default function PrivacyPolicyComponent() {
    const width = useWindowWidth();
    const breakpoint = 720;

    if (width < breakpoint) {
        return (
            <div className="d-f-c-c">
                <div className="w-v-90 bg-backgroundSecondary text-foregroundSecondary m-t-20">
                    <PrivacyPolicyContentComponent/>
                </div>
            </div>
        );
    } else {
        return (
            <div className="d-f-c-c">
                <div className="mi-ma-w-p-80 bg-backgroundSecondary text-foregroundSecondary m-t-20">
                    <PrivacyPolicyContentComponent/>
                </div>
            </div>
        );
    }
}
