import React from "react";
import ButtonComponent from "../_shared/button/button.component";
import { routerData } from "../../assets/data/router.data";

export default function HomeButtonsComponent(props) {
  return (
    <div
      className={`d-f-c-c t-a-c p-h-20 p-w-10 ${props.width ?? ""} ${props.color ?? ""}`}
    >
      <ButtonComponent
        data={routerData.home}
        font={props.font}
        border={props.border}
        uber={true}
        deliveroo={true}
      />
    </div>
  );
}
