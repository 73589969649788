import React, { useEffect, useState } from "react";
import MenuMenuComponent from "../../components/menus/menu-menu.component";
import MenuDrinksComponent from "../../components/menus/menu-drinks.component";
import BottomBarComponent from "../../components/_shared/bottom-bar/bottom-bar.component";
import MenuOrDrinkComponent from "../../components/_shared/choice-category/choice-category.component";
import TopBarComponent from "../../components/_shared/top-bar/top-bar.component";

export default function MenusPage() {
  const [menuOrDrink, setMenuOrDrink] = useState(true);
  const [sticky, setSticky] = useState(false);

  function setSticked() {
    if (window.scrollY >= 1) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  }

  window.addEventListener("scroll", setSticked);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  function menuOrDrinkHandle() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setMenuOrDrink(!menuOrDrink);
  }

  return (
    <div>
      <TopBarComponent
        class={"bg-backgroundSecondary text-foregroundSecondary"}
        height={134}
        font={"f-p"}
        border={"b-h-p"}
        sticky={true} // Permet de ne pas mettre le shadow
        stickyHeight={206}
      />
      <MenuOrDrinkComponent
        menuOrDrinkHandle={menuOrDrinkHandle}
        class={`bg-backgroundSecondary text-foregroundSecondary ${sticky ? "sticky-menus" : ""}`}
      />
      {menuOrDrink ? (
        <MenuMenuComponent
          class={"bg-backgroundSecondary text-foregroundSecondary"}
          bh={"b-h-p"}
          bt={"b-t-p"}
          bb={"b-b-p"}
        />
      ) : (
        <MenuDrinksComponent
          class={"bg-backgroundSecondary text-foregroundSecondary"}
          bh={"b-h-p"}
          bt={"b-t-p"}
          bb={"b-b-p"}
        />
      )}
      <BottomBarComponent class={"bg-backgroundSecondary text-foregroundSecondary"} border={"b-t-p"} website={false} />
    </div>
  );
}
