import React from "react";
import { SiUbereats } from "react-icons/si";

export default function ButtonUberComponent(props) {
  return (
    <div className={props.class}>
      <a
        href="https://www.ubereats.com/fr/store/o-jardin-secret/WI1LazKMTamLSS9yGvZ1_Q"
        target="_blank"
        rel="noreferrer"
      >
        <button className="uber" type="button">
          <SiUbereats />
        </button>
      </a>
    </div>
  );
}
