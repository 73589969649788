import React from "react";
import { GiChiliPepper } from "react-icons/gi";
import FoodCardComponent from "../_shared/food-card/food-card.component";
import { drinksData } from "../../assets/data/drinks.data";
import { useTranslation } from "react-i18next";

export default function MenuDrinksComponent(props) {
  const { t } = useTranslation();

  return (
    <div className={props.class}>
      <div className="d-f-c-c">
        <div className="w-v-90">
          <FoodCardComponent data={drinksData.aperitifs} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={drinksData.bieres} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={drinksData.whisky} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={drinksData.rhum} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={drinksData.vodka} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={drinksData.gin} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={drinksData.cocktails} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={drinksData.mocktails} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={drinksData.softs} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={drinksData.italianRedWine} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={drinksData.frenchRedWine} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={drinksData.italianWhiteWine} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={drinksData.frenchWhiteWine} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={drinksData.italianRoseWine} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={drinksData.frenchRoseWine} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={drinksData.sparklingWine} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={drinksData.champagne} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={drinksData.digestifs} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={drinksData.cafeteria} bh={props.bh} bt={props.bt} bb={props.bb}/>

          <div className="m-t-20 d-f-c-c">
            <span className="flex item-center justify-center">
              <GiChiliPepper style={{ color: "red" }} className="m-r-5" />
              <h4>{t("MENU.SPICY")}</h4>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
