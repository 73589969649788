import React from "react";
import ImageBackgroundComponent from "../_shared/image-background/image-background.component";
import HomeInformationsComponent from "./home-informations.component";
import HomeWhoWeAreComponent from "./home-who-we-are.component";
import ContactComponent from "../_shared/contact/contact.component";
import HomeButtonsComponent from "./home-buttons.component";
import {useWindowWidth} from "../hooks/window.hook";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper/modules";
import {images} from "../../assets/data/carousel.data";

function SwiperComponent() {
    return (
        <Swiper
            className="min-h-[600px]"
            slidesPerView={1}
            spaceBetween={0}
            loop={true}
            autoplay={true}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation, Autoplay]}
        >
            {images.map((image, i) => {
                return (
                    <SwiperSlide
                        className="min-h-[600px]"
                        style={{
                            backgroundImage: `url(${image})`,
                            backgroundPosition: "center center",
                            backgroundSize: "cover",
                        }}
                    />
                )
            })}
        </Swiper>
    )
}

export default function HomeComponent() {
    const width = useWindowWidth();
    const breakpoint = 720;

    if (width < breakpoint) {
        return (
            <div className="d-f-c-c bg-s">
                <ImageBackgroundComponent class="first-bg"/>
                <div className="w-v-90 m-t-20">
                    <HomeButtonsComponent color="bg-backgroundSecondary text-foregroundSecondary" font="f-p" border="b-h-p"/>
                </div>
                <div className="w-v-90 m-t-20">
                    <HomeInformationsComponent color="bg-s" border="b-h-s"/>
                </div>

                <div className="w-full py-4">
                    <SwiperComponent/>
                </div>

                <div className="w-v-90 m-t-20">
                    <HomeWhoWeAreComponent color="bg-backgroundSecondary text-foregroundSecondary" border="b-h-p"/>
                </div>

                <div className="w-v-90 m-t-20">
                    <ContactComponent color="bg-s" border="b-h-s" font="f-s"/>
                </div>
            </div>
        );
    }

    return (
        <div className="d-f-c-c bg-s">
            <ImageBackgroundComponent class="first-bg"/>
            <div className="mi-w-p-90 m-t-20 d-f-r-sb">
                <HomeButtonsComponent
                    width="mi-w-p-40"
                    color="bg-backgroundSecondary text-foregroundSecondary"
                    font="f-p"
                    border="b-h-p"
                />
                <HomeInformationsComponent
                    width="mi-w-p-40"
                    color="bg-s"
                    border="b-h-s"
                />
            </div>

            <div className="w-full flex items-center justify-center gap-4 p-4">
                <div className="w-1/2 h-full">
                    <SwiperComponent/>
                </div>

                <div className="w-1/2 flex flex-col items-center px-4 gap-4">
                    <div className="w-full">
                        <HomeWhoWeAreComponent color="bg-backgroundSecondary text-foregroundSecondary" border="b-h-p"/>
                    </div>

                    <div className="w-full">
                        <ContactComponent color="bg-s" border="b-h-s" font="f-s"/>
                    </div>
                </div>
            </div>
        </div>
    );
}
