import React from "react";
import { useTranslation } from "react-i18next";
import { SiFacebook, SiGmail, SiGooglemaps, SiInstagram } from "react-icons/si";
import socialWhite from "../../../img/big/social-w.webp";
import socialBlack from "../../../img/big/social-b.webp";
import atWhite from "../../../img/big/at-w.webp";
import atBlack from "../../../img/big/at-b.webp";

export default function ContactComponent(props) {
  const { t } = useTranslation();
  return (
    <div
      className={`d-f-c-c t-a-c p-h-20 p-w-10 ${props.color ?? ""}`}
    >
      <h2 className={`${props.border ?? ""}`}>{t("CONTACT.TITLE")}</h2>
      <a className={`se-f f-s-20 ${props.font ?? ""}`} href="tel:+33142040918">
        <div className="flex item-center justify-center m-t-20">
          {props.color === "bg-s" ? (
            <img src={socialWhite} alt="socialWhite" className="mi-picto" />
          ) : (
            <img src={socialBlack} alt="socialBlack" className="mi-picto" />
          )}
          <div className="mi-w-10"></div>
          {t("CONTACT.PHONE_NUMBER")}
        </div>
      </a>
      <a
        className={`flex item-center justify-center se-f f-s-20 ${props.font ?? ""}`}
        href="mailto:ojardin92150@gmail.com"
      >
        <div className="flex item-center justify-center m-t-20">
          {props.color === "bg-s" ? (
            <img src={atWhite} alt="atWhite" className="mi-picto" />
          ) : (
            <img src={atBlack} alt="atBlack" className="mi-picto" />
          )}
          <div className="mi-w-10"></div>
          ojardin92150@gmail.com
        </div>
      </a>
      <div className="d-f-r-sa m-t-20">
        <a
          className={`flex item-center justify-center se-f f-s-20 ${props.font ?? ""}`}
          href="mailto:ojardin92150@gmail.com"
        >
          <SiGmail />
        </a>
        <div className="mi-w-10"></div>
        <a
          className={`flex item-center justify-center se-f f-s-20 ${props.font ?? ""}`}
          href="https://www.google.com/maps/place/O'Jardin+Secret/@48.8627353,2.2149123,17z/data=!3m1!4b1!4m5!3m4!1s0x47e664cc15b21ba3:0x15bc0caa65e16021!8m2!3d48.8627353!4d2.217101"
          target="_blank"
          rel="noreferrer"
        >
          <SiGooglemaps />
        </a>
        <div className="mi-w-10"></div>
        <a
          className={`flex item-center justify-center se-f f-s-20 ${props.font ?? ""}`}
          href="https://www.instagram.com/ojardinsecret/?hl=fr"
          target="_blank"
          rel="noreferrer"
        >
          <SiInstagram />
        </a>
        <div className="mi-w-10"></div>
        <a
          className={`flex item-center justify-center se-f f-s-20 ${props.font ?? ""}`}
          href="https://fr-fr.facebook.com/Le.Restaurant.Italien.de.Suresnes/"
          target="_blank"
          rel="noreferrer"
        >
          <SiFacebook />
        </a>
      </div>
    </div>
  );
}
