import React, { useEffect } from "react";
import BottomBarComponent from "../../components/_shared/bottom-bar/bottom-bar.component";
import TopBarComponent from "../../components/_shared/top-bar/top-bar.component";
import BookEventComponent from "../../components/organize-event/organize-event.component";

export default function OrganizeEventPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <TopBarComponent
        home={true}
        menu={true}
        class={"bg-s"}
        height={102}
        font={"f-s"}
        border={"b-h-s"}
        stickyHeight={102}
      />
      <BookEventComponent />
      <BottomBarComponent class={"bg-s"} border={"b-t-s"} website={true} />
    </div>
  );
}
