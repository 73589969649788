import React from "react";
import DarkLogoComponent from "../../logo/dark-logo.component";
import ClearLogoComponent from "../../logo/clear-logo.component";
import MenuLinkComponent from "../../link/menu-link.component";
import HomeLinkComponent from "../../link/home-link.component";
import ButtonComponent from "../../button/button.component";
import ContactComponent from "../../contact/contact.component";

export default function MenuModal(props) {
  return (
    <div className={`d-f-c-fs h-p-100 w-p-100 z-i-10 p-f ${props.class ?? ""} `}>
      <div className={`w-v-90 d-f-r-sb mi-h-${props.height ?? ""} ${props.font ?? ""}`}>
        {props.home ? (
          <HomeLinkComponent />
        ) : !props.home && !props.menu ? (
          <div></div>
        ) : (
          <div className="mi-w-30"></div>
        )}
        {props.class === "bg-s" ? (
          <DarkLogoComponent class="logo-200" />
        ) : (
          <ClearLogoComponent class="logo-300" />
        )}
        {props.menu ? (
          <MenuLinkComponent
            setOpened={props.setOpened}
            isOpen={props.isOpen}
          />
        ) : !props.menu && !props.home ? (
          <div></div>
        ) : (
          <div className="mi-w-30"></div>
        )}
      </div>
      <div className={`d-f-c-c w-v-90 mi-h-p-m-${props.height ?? ""}`}>
        <ButtonComponent
          data={props.data}
          font={props.font}
          border={props.border}
          setOpened={props.setOpened}
          uber={true}
          deliveroo={true}
        />
      </div>
      <ContactComponent
        // width={"mi-w-p-100"}
        color={"bg-s"}
        border={"b-h-s"}
        font={"f-s"}
      />
    </div>
  );
}
