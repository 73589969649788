import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./language/en.json";
import translationFR from "./language/fr.json";
import { LanguageEnum } from "../data/lang.data";

const defaultLanguage = LanguageEnum.FR;

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

i18n
  // .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: defaultLanguage,
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
  })
  .then((result) => result);

export default i18n;
