import React from "react";
import {Helmet} from "react-helmet";
import TopBarComponent from "../../components/_shared/top-bar/top-bar.component";
import BottomBarComponent from "../../components/_shared/bottom-bar/bottom-bar.component";

export default function NotFoundPage() {
    return (
        <div>
            <Helmet>
                <title>Page non trouvée - 404 | O'Jardin Secret</title>
            </Helmet>

            <TopBarComponent
                home={true}
                menu={true}
                class={"bg-s"}
                height={102}
                font={"f-s"}
                border={"b-h-s"}
                stickyHeight={102}
            />

            <div
                className="min-h-[calc(100svh-102px)] bg-s w-full flex flex-col items-center justify-between text-center">
                <div className="w-full flex flex-col items-center justify-between my-auto">
                    <h1><span className='text-6xl'>404</span><br/>Page non trouvée</h1>
                    <p>Désolé, la page que vous recherchez n'existe pas.</p>
                </div>

                <BottomBarComponent class={"bg-s"} border={"b-t-s"} website={true}/>
            </div>
        </div>
    );
}
