import React from "react";
import "./assets/i18n/i18n";
import "./style.css";

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import ReactDOM from "react-dom/client";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import HomePage from "./pages/home/home.page";
import MenusPage from "./pages/menus/menus.page";
import MenuPage from "./pages/menu/menu.page";
import BookTablePage from "./pages/book-table/book-table.page";
import {createTheme, ThemeProvider} from "@mui/material";
import PrivacyPolicyPage from "./pages/privacy-policy/privacy-policy.page";
import OrganizeEventPage from "./pages/organize-event/organize-event.page";
import NotFoundPage from "./pages/not-found/not-found.page";

// const language = getI18n().language ? frFR : null;

const theme = createTheme({
    palette: {
        primary: {
            main: "#E8CEB0",
            darker: "#000000",
        },
        secondary: {
            main: "#3B3E34",
            darker: "#FFFFFF",
        },
    },
    typography: {
        fontFamily: "",
    },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ThemeProvider theme={theme}>
        <React.StrictMode>
            <Router>
                <Switch>
                    <Route exact path="/" component={HomePage}/>
                    <Route path="/menu" component={MenuPage}/>
                    <Route path="/menus" component={MenusPage}/>
                    <Route path="/book-table" component={BookTablePage}/>
                    <Route path="/organize-event" component={OrganizeEventPage}/>
                    <Route path="/privacy-policy" component={PrivacyPolicyPage}/>
                    <Route component={NotFoundPage}/>
                </Switch>
            </Router>
        </React.StrictMode>
    </ThemeProvider>
);
