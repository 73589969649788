import React from "react";
import { SiDeliveroo } from "react-icons/si";

export default function ButtonDeliverooComponent(props) {
  return (
    <div className={props.class}>
      <a
        href="https://deliveroo.fr/fr/menu/paris/suresnes-mont-valerien/ojardin-secret?geohash=u09tgpxm9vpn"
        target="_blank"
        rel="noreferrer"
      >
        <button className="deliveroo" type="button">
          <SiDeliveroo />
        </button>
      </a>
    </div>
  );
}
