import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {LanguageEnum} from "../../../assets/data/lang.data";
import {FR, US} from "country-flag-icons/react/3x2";

export default function LanguageComponent() {
    const {i18n} = useTranslation();
    const [localLanguage, setLocalLanguage] = useState(null);

    useEffect(() => {
        const storedLanguage = localStorage.getItem("language");
        if (storedLanguage) {
            setLocalLanguage(storedLanguage);
        }
        switch (localLanguage) {
            case LanguageEnum.EN:
                i18n.changeLanguage(LanguageEnum.EN).then((result) => result);
                break;
            case LanguageEnum.FR:
            default:
                i18n.changeLanguage(LanguageEnum.FR).then((result) => result);
                break;
        }
    }, [i18n, localLanguage]);

    function changeLang(lang) {
        setLocalLanguage(lang);
        localStorage.setItem("language", lang);
    }

    return (
        <div className="flex gap-2 item-center justify-center absolute top-[20px] right-[30vw]">
            <FR
                className="w-5 pointer"
                onClick={() => changeLang(LanguageEnum.FR)}
            />
            
            <US
                className="w-5 pointer"
                onClick={() => changeLang(LanguageEnum.EN)}
            />
        </div>
    );
}
