import React from "react";
import { useTranslation } from "react-i18next";

export default function HomeWhoWeAreComponent(props) {
  const { t } = useTranslation();
  return (
    <div
      className={`d-f-c-c t-a-c p-h-20 p-w-10 ${props.color}`}
    >
      <h2 className={`${props.border}`}>{t("HOME.WHO_WE_ARE.TITLE")}</h2>
      <p className="m-t-20 f-s-20">{t("HOME.WHO_WE_ARE.TEXT_1")}</p>
      <p className="m-t-10 f-s-20">{t("HOME.WHO_WE_ARE.TEXT_2")}</p>
    </div>
  );
}
