import pictureOjs1 from "../../img/big/second-bg.webp";
import pictureOjs0 from "../../img/picture-ojs-0.webp";
import pictureOjs2 from "../../img/picture-ojs-2.webp";
import pictureOjs4 from "../../img/picture-ojs-4.webp";
import pictureOjs5 from "../../img/picture-ojs-5.webp";
import pictureOjs7 from "../../img/picture-ojs-7.webp";
import pictureOjs10 from "../../img/picture-ojs-10.webp";
import pictureOjs11 from "../../img/picture-ojs-11.webp";
import pictureOjs12 from "../../img/picture-ojs-12.webp";
import pictureOjs13 from "../../img/picture-ojs-13.webp";
import pictureOjs14 from "../../img/picture-ojs-14.webp";
import pictureOjs15 from "../../img/picture-ojs-15.webp";
import pictureOjs16 from "../../img/picture-ojs-16.webp";
import pictureOjs17 from "../../img/picture-ojs-17.webp";
import pictureOjs18 from "../../img/picture-ojs-18.webp";

export const images = [
    pictureOjs1,
    pictureOjs0,
    pictureOjs2,
    pictureOjs4,
    pictureOjs5,
    pictureOjs7,
    pictureOjs10,
    pictureOjs11,
    pictureOjs12,
    pictureOjs13,
    pictureOjs14,
    pictureOjs15,
    pictureOjs16,
    pictureOjs17,
    pictureOjs18
];