import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
    Alert,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    MenuItem,
    Select,
    Slider,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { getI18n, useTranslation } from "react-i18next";
import { fr } from "date-fns/locale";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as emailjs from "@emailjs/browser";

export default function FormBookTableComponent() {
    const lang = getI18n().language;
    const language = lang === "fr" ? fr : null;
    const { t } = useTranslation();

    const { control, handleSubmit, watch, reset, setValue } = useForm({
        defaultValues: {
            date: null,
            choice: "",
            time: "",
            number: 0,
            name: "",
            email: "",
            phone: "",
            condition: false,
        },
    });

    const [loading, setLoading] = useState(false);
    const [ok, setOk] = useState(false);
    const [ko, setKo] = useState(false);
    const [timeOptions, setTimeOptions] = useState([]);

    // Ref pour l'input du MobileDatePicker
    const dateInputRef = useRef(null);

    const selectedDate = watch("date");
    const choiceValue = watch("choice");

    const tomorrow = useMemo(() => {
        const t = new Date();
        t.setDate(t.getDate() + 1);
        return t;
    }, []);

    const errorFieldSx = {
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "red",
        },
    };
    const helperTextProps = {
        style: { color: "red", fontWeight: "bold" },
        className: "se-f",
    };

    // Réinitialisation du service et du temps lorsque la date change
    useEffect(() => {
        if (selectedDate) {
            setValue("choice", "");
            setValue("time", "");
        }
    }, [selectedDate, setValue]);

    // Fonctions de calcul des créneaux
    const openHours = useCallback(() => {
        const d = new Date(tomorrow);
        d.setHours(11, 30, 0, 0);
        return d;
    }, [tomorrow]);

    const closeHours = useCallback(() => {
        const d = new Date(tomorrow);
        d.setHours(14, 0, 0, 0);
        return d;
    }, [tomorrow]);

    const openHours2 = useCallback(() => {
        const d = new Date(tomorrow);
        d.setHours(18, 0, 0, 0);
        return d;
    }, [tomorrow]);

    const closeHours2 = useCallback(() => {
        const d = new Date(tomorrow);
        d.setHours(21, 30, 0, 0);
        return d;
    }, [tomorrow]);

    const getTimeOptions = useCallback((startFn, endFn) => {
        const options = [];
        const start = startFn();
        const end = endFn();
        let current = new Date(start);
        while (current <= end) {
            options.push(new Date(current));
            current.setMinutes(current.getMinutes() + 15);
        }
        return options;
    }, []);

    useEffect(() => {
        if (selectedDate) {
            if (choiceValue === "1" || choiceValue === "3") {
                setTimeOptions(getTimeOptions(openHours, closeHours));
            } else if (choiceValue === "2") {
                setTimeOptions(getTimeOptions(openHours2, closeHours2));
            }
        }
    }, [selectedDate, choiceValue, getTimeOptions, openHours, closeHours, openHours2, closeHours2]);

    const onSubmit = async (data) => {
        setLoading(true);
        let timeValue = "";
        if (data.time) {
            timeValue = format(new Date(data.time), "HH:mm");
        }
        const formData = {
            date: data.date ? format(data.date, "dd/MM/yyyy") : "",
            time: timeValue,
            number: data.number,
            name: data.name,
            email: data.email,
            phone: data.phone,
        };

        try {
            await emailjs.send(
                "service_rz3yueg",
                "template_5ubb0rl",
                formData,
                "0d20fLIJpS0f1_C15"
            );
            setOk(true);
            reset();
        } catch (error) {
            setKo(true);
            console.error(error);
        }
        setLoading(false);
    };

    const sliderErrorSx = (hasError) =>
        hasError
            ? {
                "& .MuiSlider-track": { color: "red" },
                "& .MuiSlider-rail": { backgroundColor: "red" },
                "& .MuiSlider-thumbColorSecondary": { backgroundColor: "red" },
            }
            : {};
    const checkboxErrorSx = (hasError) =>
        hasError ? { color: "red", "&.Mui-checked": { color: "red" } } : {};

    return (
        <form className="d-f-c-fs-c m-t-20 mi-ma-w-p-90" onSubmit={handleSubmit(onSubmit)}>
            <h3 className="t-a-l">{t("BOOK_TABLE.FORM.DATE_TIME.TITLE")}</h3>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={language}>
                <Controller
                    name="date"
                    control={control}
                    rules={{ required: t("BOOK_TABLE.FORM.DATE_TIME.ERROR_DATE") }}
                    render={({ field, fieldState: { error } }) => (
                        <MobileDatePicker
                            {...field}
                            onChange={(value) => field.onChange(value)}
                            onClose={() => {
                                if (dateInputRef.current) {
                                    dateInputRef.current.blur();
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    color="secondary"
                                    placeholder={t("BOOK_TABLE.FORM.DATE_TIME.PLACEHOLDER_1")}
                                    error={!!error}
                                    helperText={error ? error.message : ""}
                                    FormHelperTextProps={helperTextProps}
                                    sx={error ? errorFieldSx : {}}
                                    className="m-t-10 mi-w-p-100 se-f"
                                    inputRef={(instance) => {
                                        if (typeof params.inputRef === "function") {
                                            params.inputRef(instance);
                                        } else if (params.inputRef) {
                                            params.inputRef.current = instance;
                                        }
                                        dateInputRef.current = instance;
                                    }}
                                />
                            )}
                            minDate={tomorrow}
                            disableHighlightToday
                            showToolbar={false}
                            showDaysOutsideCurrentMonth
                        />
                    )}
                />
            </LocalizationProvider>

            {/* Le reste du composant */}
            {selectedDate && (
                <>
                    <Controller
                        name="choice"
                        control={control}
                        rules={{
                            required: t("BOOK_TABLE.FORM.DATE_TIME.ERROR_SERVICE"),
                            validate: (value) =>
                                value !== "" || t("BOOK_TABLE.FORM.DATE_TIME.ERROR_SERVICE"),
                        }}
                        render={({ field, fieldState: { error } }) => {
                            const date = watch("date");
                            if (date && new Date(date).getDay() === 0) {
                                return (
                                    <>
                                        <Select
                                            {...field}
                                            color="secondary"
                                            className="m-t-10 mi-w-p-100 se-f"
                                            displayEmpty
                                            error={!!error}
                                            sx={error ? errorFieldSx : {}}
                                            onChange={(e) => {
                                                field.onChange(e.target.value);
                                                setValue("time", ""); // Réinitialise le champ "time"
                                            }}
                                            renderValue={(selected) =>
                                                selected
                                                    ? t(`BOOK_TABLE.FORM.DATE_TIME.CHOICE_${selected}`)
                                                    : t("BOOK_TABLE.FORM.DATE_TIME.PLACEHOLDER_SERVICE")
                                            }
                                        >
                                            <MenuItem value="">
                                                <em>{t("BOOK_TABLE.FORM.DATE_TIME.PLACEHOLDER_SERVICE")}</em>
                                            </MenuItem>
                                            <MenuItem key="3" value="3" className="se-f">
                                                {t("BOOK_TABLE.FORM.DATE_TIME.CHOICE_3")}
                                            </MenuItem>
                                        </Select>
                                        {error && (
                                            <Typography
                                                variant="caption"
                                                sx={{ color: "red", fontWeight: "bold", ml: 2 }}
                                                className="se-f m-t-3"
                                            >
                                                {error.message}
                                            </Typography>
                                        )}
                                    </>
                                );
                            } else {
                                return (
                                    <>
                                        <Select
                                            {...field}
                                            color="secondary"
                                            className="m-t-10 mi-w-p-100 se-f"
                                            displayEmpty
                                            error={!!error}
                                            sx={error ? errorFieldSx : {}}
                                            onChange={(e) => {
                                                field.onChange(e.target.value);
                                                setValue("time", ""); // Réinitialise le champ "time"
                                            }}
                                            renderValue={(selected) =>
                                                selected
                                                    ? t(`BOOK_TABLE.FORM.DATE_TIME.CHOICE_${selected}`)
                                                    : t("BOOK_TABLE.FORM.DATE_TIME.PLACEHOLDER_SERVICE")
                                            }
                                        >
                                            <MenuItem value="">
                                                <em>{t("BOOK_TABLE.FORM.DATE_TIME.PLACEHOLDER_SERVICE")}</em>
                                            </MenuItem>
                                            <MenuItem key="1" value="1" className="se-f">
                                                {t("BOOK_TABLE.FORM.DATE_TIME.CHOICE_1")}
                                            </MenuItem>
                                            <MenuItem key="2" value="2" className="se-f">
                                                {t("BOOK_TABLE.FORM.DATE_TIME.CHOICE_2")}
                                            </MenuItem>
                                        </Select>
                                        {error && (
                                            <Typography
                                                variant="caption"
                                                sx={{ color: "red", fontWeight: "bold", ml: 2 }}
                                                className="se-f m-t-3"
                                            >
                                                {error.message}
                                            </Typography>
                                        )}
                                    </>
                                );
                            }
                        }}
                    />

                    {(selectedDate?.getDay() === 0 ||
                        choiceValue === "1" ||
                        choiceValue === "2") && (
                        <Controller
                            name="time"
                            control={control}
                            rules={{ required: t("BOOK_TABLE.FORM.DATE_TIME.ERROR_TIME") }}
                            render={({ field, fieldState: { error } }) => (
                                <>
                                    <Select
                                        {...field}
                                        color="secondary"
                                        className="m-t-10 mi-w-p-100 se-f"
                                        displayEmpty
                                        error={!!error}
                                        sx={error ? errorFieldSx : {}}
                                        onChange={(e) => field.onChange(e.target.value)}
                                        renderValue={(selected) =>
                                            selected
                                                ? format(new Date(selected), "HH:mm")
                                                : t("BOOK_TABLE.FORM.DATE_TIME.PLACEHOLDER_2")
                                        }
                                    >
                                        <MenuItem value="">
                                            <em>{t("BOOK_TABLE.FORM.DATE_TIME.PLACEHOLDER_2")}</em>
                                        </MenuItem>
                                        {timeOptions.map((timeOption, index) => (
                                            <MenuItem key={index} value={timeOption.getTime()}>
                                                {format(timeOption, "HH:mm")}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {error && (
                                        <Typography
                                            variant="caption"
                                            sx={{ color: "red", fontWeight: "bold", ml: 2 }}
                                            className="se-f m-t-3"
                                        >
                                            {error.message}
                                        </Typography>
                                    )}
                                </>
                            )}
                        />
                    )}
                </>
            )}

            <h3 className="m-t-10 t-a-l">
                {t("BOOK_TABLE.FORM.PEOPLE_NUMBER.TITLE")}{" "}
                {watch("number") ? ` ${watch("number")}` : ""}
            </h3>
            <Controller
                name="number"
                control={control}
                rules={{
                    required: t("BOOK_TABLE.FORM.PEOPLE_NUMBER.ERROR"),
                    min: { value: 1, message: t("BOOK_TABLE.FORM.PEOPLE_NUMBER.ERROR") },
                }}
                render={({ field, fieldState: { error } }) => (
                    <Box className="d-f-c-c mi-w-p-100 m-t-10">
                        <Slider
                            {...field}
                            value={field.value}
                            min={0}
                            max={30}
                            color="secondary"
                            valueLabelDisplay="auto"
                            className="mi-ma-w-p-90"
                            onChange={(e, value) => field.onChange(value)}
                            sx={sliderErrorSx(!!error)}
                        />
                        {error && (
                            <Typography
                                variant="caption"
                                sx={{ color: "red", fontWeight: "bold", mt: 1 }}
                                className="se-f"
                            >
                                {error.message}
                            </Typography>
                        )}
                    </Box>
                )}
            />

            <h3 className="m-t-10 t-a-l">{t("BOOK_TABLE.FORM.NAME.TITLE")}</h3>
            <Controller
                name="name"
                control={control}
                rules={{ required: t("BOOK_TABLE.FORM.NAME.ERROR") }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        variant="outlined"
                        color="secondary"
                        className="mi-w-p-100 se-f m-t-10"
                        type="text"
                        placeholder={t("BOOK_TABLE.FORM.NAME.PLACEHOLDER")}
                        error={!!error}
                        helperText={error ? error.message : ""}
                        FormHelperTextProps={helperTextProps}
                        autoComplete="off"
                        sx={error ? errorFieldSx : {}}
                    />
                )}
            />

            <h3 className="m-t-10 t-a-l">{t("BOOK_TABLE.FORM.MAIL.TITLE")}</h3>
            <Controller
                name="email"
                control={control}
                rules={{ required: t("BOOK_TABLE.FORM.MAIL.ERROR") }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        variant="outlined"
                        color="secondary"
                        className="mi-w-p-100 se-f m-t-10"
                        type="email"
                        placeholder={t("BOOK_TABLE.FORM.MAIL.PLACEHOLDER")}
                        error={!!error}
                        helperText={error ? error.message : ""}
                        FormHelperTextProps={helperTextProps}
                        autoComplete="off"
                        sx={error ? errorFieldSx : {}}
                    />
                )}
            />

            <h3 className="m-t-10 t-a-l">{t("BOOK_TABLE.FORM.PHONE.TITLE")}</h3>
            <Controller
                name="phone"
                control={control}
                rules={{ required: t("BOOK_TABLE.FORM.PHONE.ERROR") }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        variant="outlined"
                        color="secondary"
                        className="mi-w-p-100 se-f m-t-10"
                        type="tel"
                        placeholder={t("BOOK_TABLE.FORM.PHONE.PLACEHOLDER")}
                        error={!!error}
                        helperText={error ? error.message : ""}
                        FormHelperTextProps={helperTextProps}
                        autoComplete="off"
                        sx={error ? errorFieldSx : {}}
                    />
                )}
            />

            <div className="flex item-center justify-center mi-w-p-100 m-t-10">
                <Controller
                    name="condition"
                    control={control}
                    rules={{ required: t("BOOK_TABLE.FORM.CONDITION_ERROR") }}
                    render={({ field, fieldState: { error } }) => (
                        <>
                            <div>
                                <div>
                                    <Checkbox
                                        {...field}
                                        checked={field.value}
                                        color="secondary"
                                        onChange={(e) => field.onChange(e.target.checked)}
                                        sx={checkboxErrorSx(!!error)}
                                    />
                                    <Link to="/privacy-policy" className="f-p se-f t-u t-a-l">
                                        {t("BOOK_TABLE.FORM.CONDITIONS")}
                                    </Link>
                                </div>
                                {error && (
                                    <Typography
                                        variant="caption"
                                        sx={{ color: "red", fontWeight: "bold", mt: 1 }}
                                        className="se-f"
                                    >
                                        {error.message}
                                    </Typography>
                                )}
                            </div>
                        </>
                    )}
                />
            </div>

            <div className="d-f-c-c mi-w-p-100 m-t-20">
                <Button type="submit" color="secondary" className="pr-f f-s-16 f-w-b f-p">
                    {loading ? (
                        <CircularProgress color="secondary" size={24} />
                    ) : (
                        t("BOOK_TABLE.FORM.SEND")
                    )}
                </Button>
            </div>

            <Snackbar
                open={ok}
                autoHideDuration={5000}
                onClose={() => setOk(false)}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={() => setOk(false)}
                    severity="success"
                    className="se-f f-s-16 f-w-b d-f-r-sb t-a-l"
                >
                    {t("BOOK_TABLE.FORM.IF_SEND.TEXT_1")}
                    <br />
                    {t("BOOK_TABLE.FORM.IF_SEND.TEXT_2")}
                </Alert>
            </Snackbar>
            <Snackbar
                open={ko}
                autoHideDuration={5000}
                onClose={() => setKo(false)}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={() => setKo(false)}
                    severity="error"
                    className="se-f f-s-16 f-w-b d-f-r-sb t-a-l"
                >
                    {t("BOOK_TABLE.FORM.IF_ERROR.TEXT_1")}
                    <br />
                    {t("BOOK_TABLE.FORM.IF_ERROR.TEXT_2")}
                </Alert>
            </Snackbar>
        </form>
    );
}
