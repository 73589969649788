import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function BottomBarComponent(props) {
  const { t } = useTranslation();

  return (
    <div className={props.class}>
      <div className="d-f-c-c p-t-20 t-a-c">
        {props.website ? (
          <>
            <div className={`d-f-c-c p-t-10 w-v-90 ${props.border ?? ""}`}>
              <Link to="/privacy-policy" className="f-s se-f">
                {t("BOTTOM_BAR.PRIVACY_POLICY")}
              </Link>
            </div>
            <p className={`d-f-c-c m-h-10 w-v-90 f-w-b`}>
              {t("BOTTOM_BAR.COPYRIGHT")}
            </p>
          </>
        ) : (
          <p className={`d-f-c-c p-h-10 w-v-90 f-w-b t-a-c ${props.border ?? ""}`}>
            {t("BOTTOM_BAR.COPYRIGHT")}
          </p>
        )}
      </div>
    </div>
  );
}
