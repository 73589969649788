import React from "react";
import ImageBackgroundComponent from "../_shared/image-background/image-background.component";
import ContactComponent from "../_shared/contact/contact.component";
import FormComponent from "../_shared/form/form.component";
import {useWindowWidth} from "../hooks/window.hook";

export default function OrganizeEventComponent() {
    const width = useWindowWidth();
    const breakpoint = 720;

    if (width < breakpoint) {
        return (
            <div className="d-f-c-c bg-s">
                <ImageBackgroundComponent class="birthday-bg"/>
                <div className="w-v-90 m-t-20">
                    <FormComponent
                        title={"ORGANIZE_EVENT.TITLE"}
                        color={"bg-backgroundSecondary text-foregroundSecondary"}
                        border={"b-h-p"}
                        font={"f-p"}
                        form={"organize-event"}
                    />
                </div>
                <ImageBackgroundComponent class="interior-bg-s m-t-20"/>
                <div className="w-v-90 m-t-20">
                    <ContactComponent color={"bg-s"} border={"b-h-s"} font={"f-s"}/>
                </div>
            </div>
        );
    } else {
        return (
            <div className="d-f-c-c bg-s">
                <ImageBackgroundComponent class="birthday-bg"/>

                <div className="d-f-c-c mi-w-p-90 m-t-20 d-f-r-sb">
                    <FormComponent
                        title={"ORGANIZE_EVENT.TITLE"}
                        color={"bg-backgroundSecondary text-foregroundSecondary"}
                        border={"b-h-p"}
                        font={"f-p"}
                        width={"mi-w-v-80"}
                        form={"organize-event"}
                    />
                </div>

                <div className="mi-w-p-100 m-t-20 flex item-center justify-center">
                    <div className="mi-w-p-50">
                        <ImageBackgroundComponent class="interior-bg mi-w-p-50"/>
                    </div>
                    <div className="mi-w-p-5"></div>
                    <div className="mi-w-p-40">
                        <ContactComponent color={"bg-s"} border={"b-h-s"} font={"f-s"}/>
                    </div>

                    <div className="mi-w-p-5"></div>
                </div>
            </div>
        );
    }
}
