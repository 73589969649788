import React from "react";
import logo from "../../../img/big/dark-logo.webp";
import LanguageComponent from "../language/language.component";

export default function DarkLogoComponent(props) {
  return (
    <div className="bg-s">
      <div className="d-f-c-c p-h-10">
        <LanguageComponent />
        <img src={logo} alt="logo" className={props.class} />
      </div>
    </div>
  );
}
